export const services = [
  {
    icon: 'fas fa-wifi',
    title: 'Offline mode',
    description:
      'Works seamlessly without wifi. Qeerio stores all your offline actions and syncronizes with the cloud when it get back online.',
  },
  {
    icon: 'fas fa-palette',
    title: 'Branding',
    description:
      'Immerse your guests from the get-go. Customize Qeerio to match your event and brand colors.'
  },
  {
    icon: 'fas fa-qrcode',
    title: 'QR code',
    description:
      'Speed up check-in by scanning guests personal QR code'
  },
  {
    icon: 'fas fa-cloud-upload-alt',
    title: 'Cloud syncronization',
    description:
      'All your event and guest informations are safely stored on the cloud.'
  },
  {
    icon: 'fas fa-file-import',
    title: 'Excel import',
    description:
      'Import thousand of guests instanyly using excel sheets'
  },
  {
    icon: 'fas fa-file-export',
    title: 'Check-in report',
    description:
      'Instantly generate a report of your event check-in. ',
  },
  {
    icon: 'fas fa-sync-alt',
    title: 'Real time update',
    description:
      'Check-in updates in real time between all devices.'
  },
  {
    icon: 'fas fa-lock',
    title: 'GDPR',
    description:
      'Built to protect your data. Qeerio is GDPR at it\'s core'
  },
  {
    icon: 'fas fa-chart-line',
    title: 'Live analytics',
    description:
      'Get real time chart analytics during your check-in'
  }
];
